import React, {
    memo,
    useCallback,
    Fragment,
    useContext,
    useState,
    useEffect,
} from "react";

//context
import { ContextCreate } from "../../Autenticacion/Context";

//Librerias
import { Link, useHistory } from "react-router-dom";

//componentes de material-UI
import {
    AppBar,
    Toolbar,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    Hidden,
    Typography,
    Box,
    Container,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";

//Iconos Material-UI
import { Menu as MenuIcon, PersonOutline as PersonIcon } from "@material-ui/icons";

//Style Material-UI
import { makeStyles } from "@material-ui/core/styles";

//Componentes
import MenuDrawer from "./drawerMenu";
import ChatBot from "../../components/Wilson/chatBot";

//Estilos de mi componente
const MenuWilsonStyle = makeStyles((theme) => ({
    avatarInterno: {
        width: "90px",
        height: "90px",
    },
    avatar: {
        marginLeft: "15px",
        width: "30px",
        height: "30px",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarMenuActive: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - 15rem)`,
            marginLeft: "15rem",
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    main: {
        display: "flex",
        position: "relative",
        flexGrow: 1,
        top: "46px",
        marginLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    mainMenuActive: {
        top: "46px",
        display: "flex",
        position: "relative",
        flexGrow: 1,
        [theme.breakpoints.up("sm")]: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: "15rem",
        },
    },
    link: {
        textDecoration: "none",
        color: "white",
    },
    toolbarHeader: {
        minHeight: "45px",
    },
    container: {
        width: "1030px",
    },
}));

const MenuWilson = ({ children }) => {
    //===============================================================================================================================================
    //========================================== Context ============================================================================================
    //===============================================================================================================================================
    const { strData, cerrarSesion, informacionCambiada, guardaCambiosDeLaInformacion } =
        useContext(ContextCreate);

    //===============================================================================================================================================
    //========================================== States =============================================================================================
    //===============================================================================================================================================
    const [openMenu, setOpenMenu] = useState(false);
    const [openMenuProfile, setOpenMenuProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [selectedList, setSelectedList] = useState(0);
    const [IsTest, setIsTest] = useState(false);
    //===============================================================================================================================================
    //========================================== Hooks ==============================================================================================
    //===============================================================================================================================================
    let history = useHistory();

    const theme = useTheme();
    const movil = useMediaQuery(theme.breakpoints.down("sm"));
    //===============================================================================================================================================
    //========================================== Funciones ============================================================================================
    //===============================================================================================================================================

    const toggleDrawer = useCallback((e, flag) => {
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }
        setOpenMenu(flag);
    }, []);

    const toggleProfile = useCallback(() => {
        setOpenMenuProfile(!openMenuProfile);
    }, [openMenuProfile]);

    const handleProfile = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, []);

    const closeSesion = useCallback(() => {
        if (informacionCambiada) {
            let result = window.confirm(
                "¿Desea salir de la página sin guardar los cambios?"
            );
            if (result) {
                guardaCambiosDeLaInformacion();
                setOpenMenuProfile(!openMenuProfile);
                cerrarSesion();
            }
        } else {
            setOpenMenuProfile(!openMenuProfile);
            cerrarSesion();
        }
        // eslint-disable-next-line
    }, [informacionCambiada]);

    const cambiarInfo = useCallback(
        (url, index) => {
            if (informacionCambiada) {
                let result = window.confirm(
                    "¿Desea salir de la página sin guardar los cambios?"
                );
                if (result) {
                    guardaCambiosDeLaInformacion();
                    history.push(url);
                    setSelectedList(index);
                }
            } else {
                history.push(url);
                setSelectedList(index);
            }
        },
        [informacionCambiada, history, guardaCambiosDeLaInformacion]
    );

    const handleListItemClick = (index) => {
        setSelectedList(index);
    };

    //===============================================================================================================================================
    //============================================= UseEffect  ======================================================================================
    //===============================================================================================================================================
    useEffect(() => {
        if (strData.strRolApp) {
            for (let i = 0; i < strData.strRolApp.length; i++) {
                if (strData.strRolApp[i].strNombre === "PRUEBA CHATBOT") {
                    setIsTest(true);
                }
            }
        }
    }, [strData]);

    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================

    const classes = MenuWilsonStyle();
    return (
        <Fragment>
            <AppBar className={!openMenu ? classes.appBar : classes.appBarMenuActive}>
                <Toolbar className={classes.toolbarHeader}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                        {!openMenu ? (
                            <IconButton onClick={(e) => toggleDrawer(e, true)}>
                                <MenuIcon style={{ color: "#ffffff" }} />
                            </IconButton>
                        ) : null}
                        <Typography variant="h6">
                            <Link
                                to="/wilson/inicio"
                                className={classes.link}
                                onClick={() => cambiarInfo("/wilson/inicio", 0)}
                            >
                                WILSON
                            </Link>
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Hidden smDown>
                            <Typography variant="subtitle1">
                                {strData ? strData.strUsuario : "undefined"}
                            </Typography>
                        </Hidden>
                        <IconButton
                            style={{ padding: "0px" }}
                            onClick={(e) => {
                                toggleProfile();
                                handleProfile(e);
                            }}
                        >
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={openMenuProfile}
                            onClose={toggleProfile}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                padding="0px 15px"
                            >
                                <Box>
                                    <Avatar className={classes.avatarInterno}>
                                        <PersonIcon className={classes.avatarInterno} />
                                    </Avatar>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                >
                                    <Box padding="6px 16px" maxWidth="200px">
                                        <Typography noWrap>
                                            <b>{`${
                                                strData ? strData.strNombre : undefined
                                            } ${
                                                strData ? strData.strApellidos : undefined
                                            }`}</b>
                                        </Typography>
                                        <Typography variant="caption" noWrap>
                                            {strData ? strData.strEmail : undefined}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <MenuItem>
                                            <Link
                                                to="/wilson/perfil"
                                                onClick={() => {
                                                    toggleProfile();
                                                    handleListItemClick(0);
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                }}
                                            >
                                                Información Personal
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={toggleProfile}>
                                            <a
                                                href="https://choucairtesting-my.sharepoint.com/person.aspx"
                                                rel="noopener noreferrer"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                }}
                                                target="_blank"
                                            >
                                                Mi perfil de Office 365
                                            </a>
                                        </MenuItem>
                                        <MenuItem onClick={() => closeSesion()}>
                                            Cerrar sesión
                                        </MenuItem>
                                    </Box>
                                </Box>
                            </Box>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <MenuDrawer
                open={openMenu}
                toggleDrawer={toggleDrawer}
                cambiarInfo={cambiarInfo}
                selectedList={selectedList}
            />
            {movil ? (
                <main className={!openMenu ? classes.main : classes.mainMenuActive}>
                    <Container className={classes.container}>
                        {children} 
                        {/* {<ChatBot />} */}
                    </Container>
                </main>
            ) : (
                <Box display="flex" flexDirection="row" justifyContent="center">
                    <Box>
                        <main
                            className={!openMenu ? classes.main : classes.mainMenuActive}
                        >
                            <Container className={classes.container}>
                                {children}
                                {/* {IsTest ? null : <ChatBot />} */}
                            </Container>
                        </main>
                    </Box>
                </Box>
            )}
        </Fragment>
    );
};

export default memo(MenuWilson);
